import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import PageLayout from '../../../shared/PageLayout';
import Badge from '../../../page-components/homePage/Articles/Badge';
import { StaticImage } from 'gatsby-plugin-image';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

import axios, { apiBaseUrl } from '../../../shared/Helpers/axios.js';

const ArticlePage = ({ location, serverData }) => {
  const links = location.pathname
    .slice(1)
    .split('/')
    .filter((path) => path.length);
  const {
    article,
    categories,
    layoutData: { contact },
  } = serverData;

  const createdAt = article?.attributes?.createdAt;
  const date = createdAt
    ? new Date(article.attributes?.createdAt).toLocaleDateString()
    : '';
  const formattedDate = date.split('/').join(' / ');
  return (
    <PageLayout contact={contact} categories={categories}>
      <div className="w-90-lg w-full mx-auto">
        <div className="container mx-auto px-6 py-12">
          <div className="flex items-center flex-wrap gap-2 gap-4-lg">
            <Link to="/" className="text-base text-sm text-gray-300">
              Accueil
            </Link>
            {links.map((link, index) => (
              <div className="flex gap-2 gap-4-lg" key={link}>
                <span className="text-base text-sm text-gray-300">/</span>
                <Link
                  key={link}
                  to={`${index > 0 ? '/' + links[index - 1] : ''}/${link}`}
                  className="text-base text-sm text-gray-300"
                >
                  {link[0].toUpperCase() + link.slice(1)}
                </Link>
              </div>
            ))}
          </div>
          <div className="max-w-800-px w-75-lg w-full mx-auto mt-6 flex flex-col items-start">
            <h1 className="text-5xl-lg text-2xl text-white font-bold mb-3">
              {article?.attributes?.title ?? ''}
            </h1>
            <span className="text-base-lg text-sm text-white mb-4">
              {formattedDate}
            </span>
            <Badge
              bgColor={article.attributes?.category?.data?.attributes?.color}
            >
              {article?.attributes?.category?.data?.attributes?.name}
            </Badge>
            <img
              src={`${apiBaseUrl}${article?.attributes?.cover?.data?.attributes?.url}`}
              alt={article.attributes.title}
              className="w-full h-auto my-6 object-cover"
            />
            <p className="text-base text-white mb-8">
              <BlocksRenderer content={article?.attributes?.text} />
            </p>
            <div className="flex items-center gap-6">
              <span className="font-semibold text-base text-white">
                Partager sur
              </span>
              <Link to="/">
                <StaticImage
                  src="../../../images/icons/fb.svg"
                  layout="fixed"
                  width={30}
                  height={30}
                />
              </Link>
              <Link to="/">
                <StaticImage
                  src="../../../images/icons/x.svg"
                  layout="fixed"
                  width={30}
                  height={30}
                />
              </Link>
              <Link to="/">
                <StaticImage
                  src="../../../images/icons/in.svg"
                  layout="fixed"
                  width={30}
                  height={30}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export async function getServerData({ params }) {
  const currentSlug = params.slug;
  try {
    const { data } = await axios.get('/api/home-page', {
      params: {
        populate: {
          contact: {
            fields: [
              'instagram',
              'facebook',
              'youtube',
              'twitter',
              'linkedin',
              'contactEmail',
            ],
          },
        },
      },
    });
    const { data: categoryData } = await axios.get('/api/categories', {
      params: {
        fields: ['name', 'slug'],
      },
    });
    const { data: articleData } = await axios.get('/api/articles', {
      params: {
        pagination: {
          pageSize: 100000,
        },
        sort: 'createdAt:desc',
        populate: {
          category: {
            fields: ['name', 'slug', 'color'],
          },
          cover: {
            fields: ['url'],
          },
        },
        filters: {
          slug: { $eq: currentSlug },
        },
      },
    });

    return {
      props: {
        categories: categoryData.data,
        article: articleData.data[0],
        layoutData: {
          ...data.data.attributes,
        },
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

ArticlePage.propTypes = {
  location: PropTypes.object,
  serverData: PropTypes.object,
  category: PropTypes.string,
};

export default ArticlePage;
